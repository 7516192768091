import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { SWRConfig } from 'swr';

// routing
import Routes from 'routes';

// project imports
import { COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC } from 'constants/cookies';
import { AuthProvider } from 'context/authContext';
import { ManagedUIContext } from 'context/uiContext';
import { initGA } from 'libs/ga4';
import fetcher from 'services/utils/fetcher';

function App() {
    const [cookies] = useCookies([COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC]);

    useEffect(() => {
        if (cookies[COOKIE_POLICY_UNNEC] === 'yes') {
            initGA();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ManagedUIContext>
            <SWRConfig
                value={{
                    refreshInterval: 0,
                    revalidateOnFocus: false,
                    shouldRetryOnError: false,
                    fetcher,
                }}
            >
                <AuthProvider>
                    <Routes />
                </AuthProvider>
            </SWRConfig>
        </ManagedUIContext>
    );
}

export default App;
