export const createCookie = (name, value, expires, path) => {
    let values = [`${name}=${value}`];

    if (expires) {
        values.push(`expires=${expires}`);
    }

    if (path) {
        values.push(`path=${path}`);
    }

    values = values.filter(Boolean).join('; ');
    document.cookie = values;
};

export const deleteCookie = (name) => {
    const values = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = values;
};

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    cookies.forEach((cookie) => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
};
