import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// material-ui
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// project imports
import Dialog from 'components/extended/Dialog';
import Button from 'components/extended/Button';

// assets
import { IconMailbox, IconMapPin, IconPhoneCall, IconUserCircle } from '@tabler/icons';

const msgScope = 'layout.Header.Contact';

const ContactDialog = ({ onClose, open }) => {
    const { t } = useTranslation();

    const info = [
        {
            label: t(`${msgScope}.name.title`),
            value: t(`${msgScope}.name.content`),
            icon: IconUserCircle,
            type: 'text',
        },
        {
            label: t(`${msgScope}.phoneNumber.title`),
            value: t(`${msgScope}.phoneNumber.content`),
            icon: IconPhoneCall,
            type: 'phone',
        },
        {
            label: t(`${msgScope}.emailAddress.title`),
            value: t(`${msgScope}.emailAddress.content`),
            icon: IconMailbox,
            type: 'mail',
        },
        {
            label: t(`${msgScope}.companyAddress.title`),
            value: t(`${msgScope}.companyAddress.content`),
            icon: IconMapPin,
            type: 'text',
        },
    ];

    const renderText = (type, value) => {
        const Component = {
            text: (
                <Typography variant="h4" color="text.primary">
                    {value}
                </Typography>
            ),
            phone: (
                <Link variant="h4" href={`tel:${value}`} underline="none" target="_blank" color="text.primary">
                    {value}
                </Link>
            ),
            mail: (
                <Link variant="h4" href={`mailto:${value}`} underline="none" target="_blank" color="text.primary">
                    {value}
                </Link>
            ),
        }[type];

        return Component;
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs">
            <DialogTitle>{t(`${msgScope}.title`)}</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <List sx={{ pt: 0 }} disablePadding>
                    {info.map(({ label, value, icon, type }) => {
                        const Icon = icon;

                        return (
                            <ListItem key={label} disableGutters>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.light', color: 'primary.main' }}>
                                        <Icon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    secondary={renderText(type, value)}
                                    primary={
                                        <Typography variant="overline" color="text.secondary" sx={{ lineHeight: 1 }}>
                                            {label}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button onClick={onClose}>{t('actions.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

ContactDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ContactDialog;
