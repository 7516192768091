import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

const StyledTextField = styled(OutlinedInput)(({ theme }) => ({
    ...theme.typography.shortShadowActive,
    borderRadius: '5px',
    fontWeight: 400,
    border: 0,

    '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
    },
}));

export default StyledTextField;
