import React from 'react';
import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <React.Fragment>
        <Outlet />
    </React.Fragment>
);

export default MinimalLayout;
