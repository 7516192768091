import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/default/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/default/Register')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/register',
            element: <AuthRegister />,
        },
    ],
};

export default AuthenticationRoutes;
