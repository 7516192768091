import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { useApp } from 'context/appContext';

export const GlobalContext = createContext(null);

export const GlobalProvider = ({ children }) => {
    const appState = useApp();
    const state = {
        ...appState,
    };

    const actions = {};

    const value = {
        ...state,
        ...actions,
    };

    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobal = () => useContext(GlobalContext);

GlobalProvider.propTypes = { children: PropTypes.node };
