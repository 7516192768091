import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const color = colors;

    const themeOption = {
        colors: color,
        paper: color.paper,
        divider: color.divider,
        heading: color.textPrimary,
        textPrimary: color.textPrimary,
        textSecondary: color.textSecondary,
        background: color.backgroundDefault,

        // shadow
        shadow: color.shadowDefault,
        insetShadow: color.insetShadow,

        customization,
    };

    switch (customization.presetColor) {
        case 'theme1':
            themeOption.boxShadow = '-3px -3px 6px rgb(58 58 58 / 30%), 3px 3px 6px rgb(0 0 0 / 30%)';
            themeOption.boxShadowActive = 'inset -3px -3px 6px rgb(58 58 58 / 10%), inset 3px 3px 6px rgb(0 0 0 / 30%)';
            break;
        case 'theme2':
            themeOption.boxShadow = '-3px -3px 6px rgb(58 58 58 / 30%), 3px 3px 6px rgb(0 0 0 / 30%)';
            themeOption.boxShadowActive = 'inset -3px -3px 6px rgb(58 58 58 / 10%), inset 3px 3px 6px rgb(0 0 0 / 30%)';
            break;
        case 'default':
        default:
            themeOption.boxShadow = '-3px -3px 6px rgba(255, 255, 255, 0.7), 3px 3px 6px rgba(12, 0, 58, 0.1)';
            themeOption.boxShadowActive = 'inset -3px -3px 6px rgba(255, 255, 255, 0.5), inset 3px 3px 6px rgba(12, 0, 58, 0.1)';
            break;
    }

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px',
                },
            },
        },
        typography: themeTypography(themeOption),
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
