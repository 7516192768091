export const STORAGE_AUTH_USER = 'authUser';
export const STORAGE_AUTH_TOKEN = 'authToken';

// Global Config
export const STORAGE_SELECTED_WAREHOUSE = 'selectedWarehouse';
export const STORAGE_ANIMATION_TOGGLE = 'animationToggle';
export const STORAGE_DNDMODE = 'dndmode';
export const STORAGE_LOCALE = 'defaultLocale';

// Account Settings
export const STORAGE_DATE_FORMATTING = 'dateFormatting';
export const STORAGE_NUMBER_FORMATTING = 'numberFormatting';
export const STORAGE_LANGUAGE = 'language';
export const STORAGE_THEME = 'theme';
