import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Head from './Head';
import Header from './Header';
import Footer from './Footer';

// import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    flexGrow: 1,
    width: '100%',
    marginTop: '85px',
    padding: '0 20px 70px',
    minHeight: 'calc(100vh - 85px)',

    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('lg')]: {
        height: 'calc(100vh - 85px)',
    },

    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.down('md')]: {
        padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
        // marginLeft: '10px',
        // marginRight: '10px',
    },
}));

function MainLayout() {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <React.Fragment>
            <Head />
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.background.default,
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                    }}
                >
                    <Toolbar>
                        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                    </Toolbar>
                </AppBar>

                {/* main content */}
                <Main theme={theme} open={leftDrawerOpened}>
                    <Outlet />
                    <Footer />
                </Main>
            </Box>
        </React.Fragment>
    );
}

export default MainLayout;
