import { useTranslation } from 'react-i18next';
import { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// project imports
import useLocalStorage from 'hooks/useLocalStorage';
import { STORAGE_LOCALE } from 'constants/store';
import { LOCALE_EN } from 'constants/settings';

export const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [locale, setLocale] = useLocalStorage(STORAGE_LOCALE, LOCALE_EN);

    const handleChangeLocale = (newLocale) => {
        setLocale(newLocale);
        window.location.reload();
    };

    const state = {
        locale,
    };

    const actions = {
        onChangeLocale: handleChangeLocale,
    };

    const value = {
        ...state,
        ...actions,
    };

    useEffect(() => {
        i18n.changeLanguage(locale);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => useContext(AppContext);

AppProvider.propTypes = { children: PropTypes.node };
