export default function componentStyleOverrides(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: `
                :root {
                    --text-primary: ${theme.textPrimary};
                }
                a {
                    color: ${theme.colors.primaryMain}
                }
            `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    borderRadius: '5px',
                    textTransform: 'unset',
                },
                contained: {
                    boxShadow: 'none',

                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
                rounded: {
                    borderRadius: '6px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.textPrimary,
                    padding: '24px',
                },
                title: {
                    fontSize: '1.125rem',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.textPrimary,
                    minWidth: '36px',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textPrimary,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: theme.textPrimary,
                    fontWeight: 500,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textPrimary,
                    '&::placeholder': {
                        color: theme.textPrimary,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.grey300,
                    },
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px',
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.primaryDark,
                    background: theme.primary200,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    boxShadow: theme.shadow,
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.backgroundDefault,
                    background: theme.textPrimary,
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                variant: 'h3',
            },
            styleOverrides: {
                root: {},
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: theme.textPrimary,
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: `${theme.divider} !important`,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {},
                icon: {
                    color: theme.textPrimary,
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {},
                toolbar: {
                    padding: 0,
                },
            },
        },
    };
}
