const config = {
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Segoe UI', sans-serif`,
    borderRadius: 10,
    seo: {
        siteName: 'Heureka Business Solutions',
        description: '',
    },
};

export default config;
