import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

// material-ui
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

// project imports
import Button from 'components/extended/Button';
import Dialog from 'components/extended/Dialog';
import createMarkup from './markup';

const DialogIMPrint = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <Button sx={{ color: 'primary.main' }} onClick={() => setOpen(true)}>
                {t('layout.Imprint.title')}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>{t('layout.Imprint.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText dangerouslySetInnerHTML={createMarkup()} />
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button onClick={handleClose}>{t('actions.close')}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogIMPrint;
