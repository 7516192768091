import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import OutlinedInput from 'components/extended/OutlinedInput';
import { supportedLanguages } from 'constants/settings';
import { useApp } from 'context/appContext';

const LanguageSection = () => {
    const { locale, onChangeLocale } = useApp();

    const handleChange = (event) => {
        onChangeLocale(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <Select
                    size="small"
                    labelId="language-select-label"
                    id="language-select"
                    input={<OutlinedInput />}
                    value={locale}
                    onChange={handleChange}
                >
                    {supportedLanguages.map(({ text, value }) => (
                        <MenuItem key={value} value={value}>
                            {text}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LanguageSection;
