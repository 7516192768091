import ReactGA from 'react-ga4';

// eslint-disable-next-line import/prefer-default-export
export const initGA = () => {
    const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-F07QMKQML9';

    ReactGA.initialize(gaMeasurementId);

    // setInterval(() => {
    //     ReactGA.event('timer_5s');
    // }, 5000);
};
