import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// project imports
import { LOCALE_DE, LOCALE_EN } from 'constants/settings';
import translationEN from './translations/en/index.json';
import translationDE from './translations/de/index.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const appLocales = [LOCALE_EN, LOCALE_DE];
export const DEFAULT_LOCALE = LOCALE_EN;

const resources = {
    [LOCALE_EN]: {
        translation: translationEN,
    },
    [LOCALE_DE]: {
        translation: translationDE,
    },
};

i18n.use(languageDetector).use(initReactI18next).init({
    lng: LOCALE_DE,
    fallbackLng: DEFAULT_LOCALE,
    debug: false,
    resources,
});

export default i18n;
