import React from 'react';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';

// project imports
import ImprintDialog from './DialogIMPrint';
import CookieSection from './CookieSection';
import LanguageSection from 'layout/MainLayout/Footer/LanguageSection';

// eslint-disable-next-line arrow-body-style
const Footer = () => {
    const renderContent = (
        <React.Fragment>
            <CookieSection />
            <Box flexGrow={1} />
            <LanguageSection />
            <ImprintDialog />
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <Hidden mdDown>
                <Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', px: 3 }}>
                    <Box height={65} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        {renderContent}
                    </Box>
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box mt={3} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    {renderContent}
                </Box>
            </Hidden>
        </React.Fragment>
    );
};

export default Footer;
