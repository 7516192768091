import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// project imports
import IOSSwitch from 'components/extended/Switch';

const msgScope = 'layout.CookieSection.CookieSettings';

const ControlledAccordions = ({ toggle, onToggle }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleClick = (event) => {
        event.stopPropagation();
    };

    const handleToggle = (event) => {
        onToggle(event.target.checked);
    };

    return (
        <div>
            <Accordion disableGutters square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary sx={{ p: 0 }} expandIcon={undefined} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                        {t(`${msgScope}.necessary.title`)}
                    </Typography>
                    <Typography color="text.secondary">{t(`${msgScope}.necessary.subtitle`)}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0, pt: 0 }}>
                    <Typography>{t(`${msgScope}.necessary.content`)}</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion disableGutters square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary sx={{ p: 0 }} expandIcon={undefined} aria-controls="panel2bh-content" id="panel2bh-header">
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                        {t(`${msgScope}.nonNecessary.title`)}
                    </Typography>
                    <IOSSwitch checked={toggle} onChange={handleToggle} onClick={handleClick} />
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0, pt: 0 }}>
                    <Typography>{t(`${msgScope}.nonNecessary.content`)}</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

ControlledAccordions.propTypes = {
    toggle: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default ControlledAccordions;
