import PropTypes from 'prop-types';
import { useState } from 'react';

// third-party
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';

// project imports
import Dialog from 'components/extended/Dialog';
import { COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC } from 'constants/cookies';
import { initGA } from 'libs/ga4';
import { deleteCookie } from 'services/cookies';
import CookieAcception from './CookieAcception';
import CookieSettings from './CookieSettings';

const deleteGACookies = () => {
    deleteCookie('_ga');
    deleteCookie('_gid');
    deleteCookie('_gat');
};

const CookieDialog = ({ open, onClose }) => {
    const [cookies, setCookie] = useCookies([COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC]);
    const [activeStep, setActiveStep] = useState(0);
    const expiredDay = dayjs().add(1, 'year').toDate();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClose = () => {
        onClose();
        setActiveStep(0);
    };

    const handleAccept = (includeNonNecessary) => {
        includeNonNecessary = includeNonNecessary ?? cookies[COOKIE_POLICY_UNNEC] === 'yes';

        setCookie(COOKIE_POLICY, 'yes', { expires: expiredDay });
        setCookie(COOKIE_POLICY_NEC, 'yes', { expires: expiredDay });
        setCookie(COOKIE_POLICY_UNNEC, includeNonNecessary ? 'yes' : 'no', { expires: expiredDay });

        if (includeNonNecessary) {
            initGA();
        } else {
            deleteGACookies();
        }

        handleClose();
    };

    const handleDecline = () => {
        setCookie(COOKIE_POLICY, 'no', { expires: expiredDay });
        setCookie(COOKIE_POLICY_NEC, 'no', { expires: expiredDay });
        setCookie(COOKIE_POLICY_UNNEC, 'no', { expires: expiredDay });

        deleteGACookies();
        handleClose();
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm">
            {activeStep === 0 && <CookieAcception onNextStep={handleNext} onAccept={handleAccept} onDecline={handleDecline} />}
            {activeStep === 1 && <CookieSettings onBackStep={handleBack} onAccept={handleAccept} onDecline={handleDecline} />}
        </Dialog>
    );
};

CookieDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default CookieDialog;
