import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import truncate from 'lodash/truncate';

const TruncatedText = ({ text, ...others }) => {
    const [showMore, setShowMore] = useState(false);

    const textCutting = truncate(text, {
        length: 250,
        separator: /,? +/,
    });
    const textToShow = showMore ? text : textCutting;

    return (
        <React.Fragment>
            <Typography {...others}>
                {textToShow}
                <Link
                    underline="none"
                    component="button"
                    hidden={textCutting === text}
                    onClick={() => setShowMore(!showMore)}
                    sx={{ p: 0, lineHeight: 'inherit', ml: 0.5 }}
                >
                    {showMore ? 'Show less' : 'Show more'}
                </Link>
            </Typography>
        </React.Fragment>
    );
};

TruncatedText.propTypes = {
    text: PropTypes.string,
};

export default TruncatedText;
