import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import { AppProvider } from 'context/appContext';
import { GlobalProvider } from 'context/globalContext';

const Homepage = Loadable(lazy(() => import('views/homepage')));

// utils routing
const NotFound = Loadable(lazy(() => import('views/pages/error/NotFound')));

const MainRoutes = {
    path: '/',
    element: (
        <AppProvider>
            <GlobalProvider>
                <MainLayout />
            </GlobalProvider>
        </AppProvider>
    ),
    children: [
        {
            path: '/',
            element: <Homepage />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ],
};

export default MainRoutes;
