import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 500,
    borderRadius: '6px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.shortShadow,

    '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        ...theme.typography.shortShadowActive,
    },
}));

export default StyledButton;
