import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// material-ui
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project imports
import Button from 'components/extended/Button';

const msgScope = 'layout.CookieSection.CookieAcception';

const CookieAcception = ({ onNextStep, onAccept, onDecline }) => {
    const { t } = useTranslation();

    return (
        <DialogContent>
            <Typography gutterBottom>{t(`${msgScope}.content`)}</Typography>
            <Stack direction="row" spacing={1.5} mt={2}>
                <Button onClick={onDecline}>{t(`${msgScope}.actions.declineAll`)}</Button>
                <Box flexGrow={1} />
                <Button onClick={onNextStep}>{t(`${msgScope}.actions.cookieSettings`)}</Button>
                <Button onClick={onAccept} sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
                    {t(`${msgScope}.actions.accept`)}
                </Button>
            </Stack>
        </DialogContent>
    );
};

export default CookieAcception;

CookieAcception.propTypes = {
    onAccept: PropTypes.func,
    onNextStep: PropTypes.func,
    onDecline: PropTypes.func,
};
