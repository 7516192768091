import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// third-party
import { useCookies } from 'react-cookie';

// project imports
import Button from 'components/extended/Button';
import { COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC } from 'constants/cookies';
import CookieDialog from './CookieDialog';

const msgScope = 'layout.CookieSection';

const CookieSection = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [cookies] = useCookies([COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC]);

    useEffect(() => {
        if (!cookies[COOKIE_POLICY]) {
            setOpen(true);
        }
    }, [cookies]);

    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)}>{t(`${msgScope}.title`)}</Button>
            <CookieDialog open={open} onClose={() => setOpen(false)} />
        </React.Fragment>
    );
};

export default CookieSection;
