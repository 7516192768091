import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// third-party
import { useCookies } from 'react-cookie';

// project imports
import { COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC } from 'constants/cookies';
import Button from 'components/extended/Button';
import TruncatedText from 'components/TruncatedText';
import AccordionPolicy from './AccordionPolicy';

const msgScope = 'layout.CookieSection.CookieSettings';

const CookieSettings = ({ onBackStep, onAccept, onDecline }) => {
    const { t } = useTranslation();
    const [cookies] = useCookies([COOKIE_POLICY, COOKIE_POLICY_NEC, COOKIE_POLICY_UNNEC]);
    const [includeNonNecessary, setIncludeNonNecessary] = useState(cookies[COOKIE_POLICY_UNNEC] !== 'no');

    const handleAccept = () => {
        onAccept(includeNonNecessary);
    };

    return (
        <React.Fragment>
            <DialogTitle>{t(`${msgScope}.title`)}</DialogTitle>
            <DialogContent>
                <TruncatedText gutterBottom text={t(`${msgScope}.content`)} />
                <AccordionPolicy toggle={includeNonNecessary} onToggle={(value) => setIncludeNonNecessary(value)} />
                <Stack direction="row" spacing={1.5} mt={2}>
                    <Button onClick={onDecline}>{t(`${msgScope}.actions.declineAll`)}</Button>
                    <Box flexGrow={1} />
                    <Button onClick={onBackStep}>{t(`${msgScope}.actions.goBack`)}</Button>
                    <Button onClick={handleAccept} sx={{ color: 'primary.main', textTransform: 'uppercase' }}>
                        {t(`${msgScope}.actions.saveAndAccept`)}
                    </Button>
                </Stack>
            </DialogContent>
        </React.Fragment>
    );
};

export default CookieSettings;

CookieSettings.propTypes = {
    onAccept: PropTypes.func,
    onBackStep: PropTypes.func,
    onDecline: PropTypes.func,
};
