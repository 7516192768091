export default function createMarkup() {
    return {
        __html: `
            <div>
                <p><strong>Angaben gemäß § 5 TMG: &nbsp;</strong></p>

                <p>Heureka&nbsp;Business Solutions GmbH<br />Elisenweg 10<br />72793 Pfullingen</p>

                <p><strong>Vertreten durch: &nbsp;</strong><br />Geschäftsführer:<br />Herr Richard Lessau<br />Herr Dr. Raymond Hemmecke</p>

                <p><strong>Kontakt:</strong><br />Telefon:&nbsp;<a href="tel:+4971219090870">+4971219090870</a><br />E-Mail:&nbsp;<a href="mailto:contact@heureka-solutions.com" target="_blank">contact@heureka-solutions.com</a></p>

                <p><strong>Umsatzsteuer-ID:</strong><br />Umsatzsteuer-Identifikationsnummer gemäß §27 a:<br />DE307707641</p>

                <p>Registergericht Stuttgart: HRB 757608</p>

                <p>
                    Seitenbetreiber: PD Dr. Raymond Hemmecke / Richard Lessau<br />Verantwortlich für den Inhalt: PD Dr. Raymond Hemmecke / Richard
                    Lessau
                </p>

                <p><strong>Haftungsausschluss (Disclaimer)&nbsp;</strong><br /><strong>Haftung für Inhalte &nbsp;</strong></p>

                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                    überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                </p>

                <p>
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                    Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>

                <p><strong>Haftung für Links &nbsp;</strong></p>

                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
                    fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
                    der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </p>

                <p>
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                    zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>

                <p><strong>Urheberrecht &nbsp;</strong></p>

                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                    Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                    schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                    kommerziellen Gebrauch gestattet.
                </p>

                <p>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                    werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
                    wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>

                <p>Quelle: https://www.e-recht24.de</p>

                <h2>Datenschutz</h2>

                <p>
                    <strong>1.1.Einleitung</strong><br />Vielen Dank für den Besuch auf unserer Webseite. Wir nehmen Datenschutz sehr ernst und sind
                    bestrebt im Rahmen unseres Webseitenangebotes Ihre personenbezogenen Daten zu schützen.
                </p>

                <p>
                    Unter personenbezogenen Daten verstehen wir alle Daten über die persönlichen und sachlichen Verhältnisse einer natürlichen Person.
                    Personenbezogene Daten, die auf unserer Webseite erhoben werden, werden ausschließlich für eigene Zwecke verwendet und nicht an
                    Dritte weitergegeben.
                </p>

                <p>
                    <strong>1.2. Rechtsgrundlage der Datenverarbeitung</strong><br />Einwilligung: Soweit wir für Verarbeitungsvorgänge
                    personenbezogener Daten Ihre Einwilligung eingeholt wurde, ist Artikel 6 Absatz 1 lit. a EU-Datenschutzgrundverordnung (DS-GVO) die
                    Rechtsgrundlage für die Datenverarbeitung.
                </p>

                <p>
                    <strong>Vertrag:</strong>&nbsp;Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
                    Vertragspartei Sie sind, ist Artikel 6 Absatz 1 lit. b DSGVO die Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur
                    Durchführung vorvertraglicher Maßnahmen erforderlich sind.
                </p>

                <p>
                    <strong>Gesetzliche Pflicht:</strong>&nbsp;Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen
                    Verpflichtung erforderlich ist, denen unser Unternehmen unterliegt, dient Artikel 6 Absatz 1 lit. c DSGVO als Rechtsgrundlage.<br />Für
                    den Fall, dass lebenswichtige Interessen von Ihnen oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten
                    erforderlich machen, ist Artikel 6 Absatz 1 lit. d DSGVO die Rechtsgrundlage.
                </p>

                <p>
                    <strong>Berechtigtes Interesse:</strong>&nbsp;Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens
                    oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
                    Interesse nicht, so dient Artikel 6 Absatz 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung. Das berechtige Interesse unseres
                    Unternehmens liegt in der Durchführung unserer Geschäftstätigkeit.<br />Wir verarbeiten Ihre Daten für folgende
                    Unternehmensinteressen: Zur Aufstellung von Teilnehmerlisten für Trainings für die jeweiligen Trainer zur Übersicht. Außerdem
                    erfolgt ggf. eine Übermittlung der jeweils relevanten Daten an den TÜV für Seminarbescheinigungen sowie an Hotels für
                    Zimmerbuchungen.
                </p>

                <p>
                    <strong>1.3. Betroffenenrechte</strong><br />Im Rahmen unserer Datenverarbeitung werden personenbezogene Daten von Ihnen
                    verarbeitet. Es stehen Ihnen die Rechte aus dem Dritten Kapitel der DS-GVO gegenüber unserem Unternehmen zu.<br />Wir beachten die
                    Rechte auf Auskunft, Berichtigung, Einschränkung der Verarbeitung, Löschung oder Übertragbarkeit ihrer personenbezogenen Daten. Sie
                    können diese Rechte wie folgt geltend machen:
                </p>

                <p>
                    <strong>Recht auf Auskunft</strong><br />Sie haben das Recht von uns eine Bestätigung darüber zu verlangen, ob wir sie betreffende
                    personenbezogene Daten verarbeiten. Ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und
                    auf folgende Informationen:
                </p>

                <p>a) die Verarbeitungszwecke;</p>

                <p>b) die Kategorien personenbezogener Daten, die verarbeitet werden;</p>

                <p>
                    c) die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch
                    offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
                </p>

                <p>
                    d) falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die
                    Kriterien für die Festlegung dieser Dauer;
                </p>

                <p>
                    e) das Bestehen eines Rechts auf Berichtigung oder Löschung der sie betreffenden personenbezogenen Daten oder auf Einschränkung der
                    Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
                </p>

                <p>f) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>

                <p>
                    g) wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die
                    Herkunft der Daten;
                </p>

                <p>
                    h) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 und — zumindest
                    in diesen Fällen — aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen
                    einer derartigen Verarbeitung für die betroffene Person.
                </p>

                <p>
                    Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über
                    die geeigneten Garantien gemäß Artikel 46 DS-GVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                </p>

                <p>
                    Wir stellen Ihnen eine Kopie der personenbezogenen Daten, die Gegenstand der Verarbeitung sind, zur Verfügung. Für alle weiteren
                    Kopien, die Sie beantragen, können wir ein angemessenes Entgelt auf der Grundlage der Verwaltungskosten verlangen. Stellen Sie den
                    Antrag auf Auskunft elektronisch, so müssen wir die Informationen in einem gängigen elektronischen Format zur Verfügung zu stellen,
                    sofern Sie nichts anderes angeben.
                </p>

                <p>Das Recht auf Erhalt einer Kopie darf die Rechte und Freiheiten anderer Personen nicht beeinträchtigen.</p>

                <p>
                    <strong>Recht auf Berichtigung</strong><br />Sie haben außerdem das Recht, unverzüglich die Berichtigung Sie betreffender
                    unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die
                    Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu verlangen.
                </p>

                <p>
                    <strong>Recht auf Löschung („Recht auf Vergessenwerden“)</strong><br />Sie haben auch das Recht, von uns zu verlangen, dass Sie
                    betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu
                    löschen, sofern einer der folgenden Gründe zutrifft:
                </p>

                <p>
                    a) Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr
                    notwendig.
                </p>

                <p>
                    b) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 I lit. a DS-GVO oder Artikel 9 Absatz 2 Buchstabe
                    a stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                </p>

                <p>
                    c) Sie legen gemäß Artikel 21 Absatz 1 Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe
                    für die Verarbeitung vor, oder Sie legen gemäß Artikel 21 Absatz 2 Widerspruch gegen die Verarbeitung ein.
                </p>

                <p>d) Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>

                <p>
                    e) Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht
                    der Mitgliedstaaten erforderlich, dem wir unterliegen.
                </p>

                <p>
                    f) Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 erhoben.
                </p>

                <p>
                    (2)Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir gemäß Absatz 1 zu deren Löschung verpflichtet, so treffen
                    wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art,
                    um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie die
                    Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt
                    haben. Dies gilt nicht, soweit die Verarbeitung erforderlich ist a) zur Ausübung des Rechts auf freie Meinungsäußerung und
                    Information; b) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
                    Mitgliedstaaten, dem wir unterliegen erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in
                    Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde; c) aus Gründen des öffentlichen Interesses im Bereich der
                    öffentlichen Gesundheit gemäß Artikel 9 Absatz 2 Buchstaben h und i sowie Artikel 9 Absatz 3; d) für im öffentlichen Interesse
                    liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89 Absatz 1,
                    soweit das in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder
                    ernsthaft beeinträchtigt, oder e) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                </p>

                <p>
                    <strong>Recht auf Einschränkung der Verarbeitung</strong><br />(1)Die betroffene Person hat das Recht, von dem Verantwortlichen die
                    Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
                </p>

                <p>
                    a) die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem
                    Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen
                </p>

                <p>
                    b) die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die
                    Einschränkung der Nutzung der personenbezogenen Daten verlangt;
                </p>

                <p>
                    c) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie
                    jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder
                </p>

                <p>
                    d) die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 eingelegt hat, solange noch nicht feststeht,
                    ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.
                </p>

                <p>
                    (2) Wurde die Verarbeitung gemäß Absatz 1 eingeschränkt, so dürfen diese personenbezogenen Daten — von ihrer Speicherung abgesehen —
                    nur mit Einwilligung der betroffenen Person oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
                    der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union
                    oder eines Mitgliedstaats verarbeitet werden. 4.5.2016 L 119/44 Amtsblatt der Europäischen Union DE
                </p>

                <p>
                    (3) Eine betroffene Person, die eine Einschränkung der Verarbeitung gemäß Absatz 1 erwirkt hat, wird von dem Verantwortlichen
                    unterrichtet, bevor die Einschränkung aufgehoben wird.
                </p>

                <p>
                    <strong
                        >Artikel 19 Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung
                        der Verarbeitung.</strong
                    ><br />Der Verantwortliche teilt allen Empfängern, denen personenbezogenen Daten offengelegt wurden, jede Berichtigung oder Löschung
                    der personenbezogenen Daten oder eine Einschränkung der Verarbeitung nach Artikel 16, Artikel 17 Absatz 1 und Artikel 18 mit, es sei
                    denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Der Verantwortliche unterrichtet die
                    betroffene Person über diese Empfänger, wenn die betroffene Person dies verlangt.
                </p>

                <p>
                    <strong>Artikel 20 Recht auf Datenübertragbarkeit</strong><br />(1)Die betroffene Person hat das Recht, die sie betreffenden
                    personenbezogenen Daten, die sie einem Verantwortlichen bereitgestellt hat, in einem strukturierten, gängigen und maschinenlesbaren
                    Format zu erhalten, und sie hat das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                    dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
                </p>

                <p>
                    a) die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a oder auf einem
                    Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b beruht und
                </p>

                <p>
                    b) die Verarbeitung mithilfe automatisierter Verfahren erfolgt. (2)Bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß
                    Absatz 1 hat die betroffene Person das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen einem
                    anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.
                </p>

                <p>
                    (3) Die Ausübung des Rechts nach Absatz 1 des vorliegenden Artikels lässt Artikel 17 unberührt. Dieses Recht gilt nicht für eine
                    Verarbeitung, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
                    öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                </p>

                <p>(4) Das Recht gemäß Absatz 2 darf die Rechte und Freiheiten anderer Personen nicht beeinträchtigen.</p>

                <p>
                    Sie haben außerdem das Recht unseren Datenschutzbeauftragten bzgl. der vorgenannten Rechte sowie zu allen mit der Verarbeitung ihrer
                    personenbezogenen Daten im Zusammenhang stehenden Fragen, zu Rate zu ziehen.
                </p>

                <p>Außerdem können unsere Kunden gegenüber den zuständigen Aufsichtsbehörden ihr Recht auf Beschwerde geltend machen.</p>

                <p>
                    <strong>Recht auf Widerspruch</strong><br />Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben,
                    jederzeit gegen die Verarbeitung sie betreffender personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f erfolgt
                    Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling. Der Verantwortliche verarbeitet die
                    personenbezogenen Daten dann nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
                    die Interessen, Rechte und Freiheiten der ihrer Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen.
                </p>

                <p>
                    2) Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch
                    gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                    Profiling, soweit es mit solcher Direktwerbung in Verbindung steht
                </p>

                <p>
                    3) Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden Ihre personenbezogenen Daten nicht mehr für die Zwecke
                    verarbeitet.
                </p>

                <p>
                    4) im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft können Sie ungeachtet der Richtlinie 2002/58/EG ihr
                    Widerspruchsrecht mittels automatisierter Verfahren ausüben, bei denen technische Spezifikationen verwendet werden.
                </p>

                <p>
                    5) Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die sie betreffende Verarbeitung sie
                    betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken
                    gem. Art. 89 Abs. 1 erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
                    liegenden Aufgabe erforderlich
                </p>

                <p>
                    Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
                    einer Aufsichtsbehörde wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
                    DS-GVO verstößt.
                </p>

                <p>
                    <strong>1.4. Webserverlogs</strong><br />Im Rahmen der Nutzung unseres Internetangebotes werden die Verbindungsinformationen in den
                    Server-Log-Dateien gespeichert.<br />Zu diesen Informationen zählen:
                </p>

                <p>
                    • IP-Adresse des aufrufenden Systems<br />• Browser Informationen wie verwendetes Betriebssystem und Bildschirmauflösung<br />•
                    aufgerufene Webseite<br />• Ursprungswebseite<br />• Zeitpunkt des Aufrufs
                </p>

                <p>
                    Die Webserverprotokolle werden ausschließlich zu Sicherheitszwecken verarbeitet.<br />Wir verwenden die Protokolldaten nur für
                    statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebotes. Wir behalten uns jedoch vor, die
                    Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen
                    Nutzung besteht.
                </p>

                <p>
                    <strong>1.5. Cookies</strong><br />Diese Webseite verwendet Cookies. Cookies sind Textdateien, die auf Ihrem Endgerät gespeichert
                    werden. Cookies können beim Aufruf der Webseite von der Webseite ausgelesen, übertragen und geändert werden. Wir nutzen Cookies nur
                    mit zufälligen, pseudonymen Identifikationsnummern. Diese Identifikationsnummern werden genutzt um Ihr Nutzungsverhalten auf unserer
                    Webseite auszuwerten. Zu keinem Zeitpunkt wird das Nutzungsprofil dem Namen einer natürlichen Person zugeordnet. Wenn Sie spezielle
                    Funktionen (wie z.B. den Warenkorb oder „angemeldet bleiben“) unserer Webseite nutzen, werden Cookies zusätzlich für diese
                    Funktionen verwendet.
                </p>

                <p>
                    Es ist jederzeit möglich der Setzung von Cookies durch entsprechende Änderung der Einstellung im Internetbrowser zu wiedersprechen.
                    Gesetze Cookies können gelöscht werden. Es wird darauf hingewiesen, dass bei Deaktivierung von Cookies möglicherweise nicht alle
                    Funktionen unserer Internetseite vollumfänglich genutzt werden können.
                </p>

                <p>Die genauen Funktionen der Cookies können sie den detaillierteren Informationen dieser Datenschutzerklärung entnehmen.</p>

                <p>
                    <strong>1.6. Kontaktformular</strong><br />Im Rahmen des Kontaktformulars haben Sie die Möglichkeit, beliebige Daten an uns zu
                    senden. Die Daten werden von unserem Webserver per Mail an das E-Mail-Postfach unseres Unternehmens weitergeleitet. Bitte beachten
                    Sie, dass die Kommunikation über das Kontaktformular nicht verschlüsselt erfolgt. Bitte nutzten Sie für vertrauliche Kommunikation
                    aus eigenem Interesse einen sicheren Kommunikationskanal.
                </p>

                <p>
                    <strong>1.7. Kommentarfunktion</strong><br />Bei Nutzung der Kommentarfunktion werden die IP-Adresse des Nutzers, Name,
                    E-Mail-Adresse und, sollte diese von Ihnen angegeben werden, Website zum Zweck der Missbrauchskontrolle gespeichert.
                </p>

                <p>
                    <strong>1.8. YouTube Videos</strong><br />Auf der Webseite werden Videos und Plugins von dem Dienstleister YouTube LLC, 901 Cherry
                    Avenue, San Bruno, CA 94066, USA eingesetzt. YouTube wird gesetzlich vertreten durch Google Inc. mit Sitz in 1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA.
                </p>

                <p>
                    Sobald über unsere Webseite ein Video auch nur im Standbild angezeigt wird, wird eine Kommunikationsverbindung zum YouTube Server
                    hergestellt über die der Dienst verschiedene Daten übermittelt bekommt. Zu diesen Daten zählt die IP Adresse Ihres Rechners,
                    eventuell auf Ihrem Rechner bereits gespeicherte Cookies und Informationen, die im Rahmen vorheriger Kontakte mit YouTube
                    gespeichert wurden. Wenn noch keine Daten gespeichert wurden, speichert der Dienst erstmalig Cookies auf Ihrem Rechner. Bei dem
                    Kontakt mit YouTube erhält der Dienst auch Informationen zu einem Nutzerkonto das sie gegebenenfalls bei YouTube haben. Weiter
                    Informationen bei der Interaktion mit dem Video wie z.B. Klick des Start-, Pause-, oder Beenden Buttons werden diese Interaktionen
                    auch an den Anbieter übertragen. Sie können die Datenverarbeitung beenden, wenn Sie eine Seite ohne Video besuchen und alle Cookies
                    von Ihrem Rechner löschen.
                </p>

                <p>
                    Weitere Informationen zur Datenverarbeitung und Hinweise zum Datenschutz durch YouTube finden Sie unter&nbsp;<a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noreferrer noopener"
                        >https://policies.google.com/privacy</a
                    >
                </p>

                <p>
                    <strong>1.9. Social Plug-Ins</strong><br />Wir setzen Social-Media-Plug-Ins ein. Wir setzen dabei die sog. 2-Klick-Lösung ein. Das
                    heißt, wenn Sie unsere Seite besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an die Anbieter dieser Plug-ins
                    weitergegeben. Den Anbieter des Plug-ins erkennen Sie über die Markierung auf dem ausgegrauten Kasten anhand des Anfangsbuchstabens.
                    Nur wenn Sie auf einen der Plug-ins klicken, werden personenbeziehbare Daten übermittelt: Durch die Aktivierung des Plug-ins werden
                    Daten automatisiert an den jeweiligen Social Media Dienst übermittelt und dort (bei ausländischen Anbietern im jeweiligen Land)
                    gespeichert. Wir haben weder Einfluss auf die erhobenen Daten und Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der
                    Datenerhebung, die Zwecke sowie die Speicherfristen bekannt. Da der Social Media Dienst die Datenerhebung insbesondere über Cookies
                    vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten Kasten über die Sicherheitseinstellungen Ihres Browsers alle
                    Cookies zu löschen.
                </p>

                <p>
                    Wenn Sie ein Plug-In aktivieren, erhält der Social Media Dienst die Information, dass Sie die entsprechende Unterseite unseres
                    Online-Angebots aufgerufen haben. Zudem werden unter anderem die IP Adresse, das Anbieter Cookie, die Browsereinstellung und weitere
                    Daten übermittelt. Dies erfolgt unabhängig davon, ob Sie ein Konto bei diesem Social Media Dienst besitzen und dort eingeloggt sind.
                    Wenn Sie ein Konto bei dem Social Media Dienst besitzen bzw. wenn Sie bei dem Dienst eingeloggt sind, werden diese Daten direkt
                    Ihrem Konto zugeordnet. Wenn Sie den aktivierten Button betätigen und z.B. die Seite verlinken, speichert der Social Media Dienst
                    auch diese Information in Ihrem Nutzerkonto und teilt dies Ihren Kontakten öffentlich mit. Wenn Sie die Zuordnung mit Ihrem Profil
                    bei dem Social Media Dienst nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen.
                </p>

                <p>
                    Der Social Media Dienst speichert diese Daten als Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung und/oder
                    bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur
                    Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
                    informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an den
                    jeweiligen Social Media Dienst wenden müssen.
                </p>

                <p>
                    Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Social Media Dienst erhalten Sie in den
                    im Folgenden mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie auch weitere Informationen zu Ihren
                    diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.
                </p>

                <p>Adressen der jeweiligen Anbieter und URL mit deren Datenschutzhinweisen:</p>

                <p>
                    Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA; http://www.facebook.com/policy.php; weitere Informationen
                    zur Datenerhebung: http://www.facebook.com/help/186325668085084, http://www.facebook.com/about/privacy/your-info-on-other
                    applications sowie http://www.facebook.com/about/privacy/your-info everyoneinfo.
                </p>

                <p>
                    Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA;
                    https://www.google.com/policies/privacy/partners/?hl=de.
                </p>

                <p>Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA; https://twitter.com/privacy.</p>

                <p>Xing AG, Gänsemarkt 43, 20354 Hamburg, DE; http://www.xing.com/privacy.</p>

                <p>LinkedIn Corporation, 2029 Stierlin Court, Mountain View, California 94043, USA; http://www.linkedin.com/legal/privacy-policy.</p>

                <p><strong>1.10. Datenschutzkontakt</strong></p>

                <p>Heureka&nbsp;Business Solutions GmbH<br />Elisenweg 10<br />72793 Pfullingen</p>

                <p><strong>Vertreten durch: &nbsp;</strong><br />Geschäftsführer:<br />Herr Richard Lessau<br />Herr Dr. Raymond Hemmecke</p>

                <p><br /></p>
            </div>
        `,
    };
}
