import axiosInstance from '../utils/axios-instance';

const resource = '/auth';

export const confirm2FaCode = async ({ code, trackingId }) => {
    const response = await axiosInstance.post(`${resource}/2fa/confirm`, { code, trackingId });

    return response.data;
};

export const resend2FaCode = async ({ trackingId }) => {
    const response = await axiosInstance.post(`${resource}/2fa/resend`, { trackingId });

    return response.data;
};

export const login = async ({ username, password }) => {
    const response = await axiosInstance.post(`${resource}/login`, { username, password });

    return response.data;
};

export const refreshToken = async ({ token }) => {
    const response = await axiosInstance.post(`${resource}/refresh`, { token });

    return response.data;
};
