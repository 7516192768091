import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Hidden, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// project imports
import Button from 'components/extended/Button';
import LogoSection from '../LogoSection';
import WarehouseLogoSection from '../WarehouseLogoSection';
import ContactDialog from './ContactDialog';
import ProfileSection from './ProfileSection';

// assets
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [openContact, setOpenContact] = useState(false);

    return (
        <React.Fragment>
            <Box component="span" sx={{ display: { xs: 'none', sm: 'block' } }}>
                <WarehouseLogoSection />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} />

            <Box component="span" sx={{ display: { xs: 'block', sm: 'block' } }}>
                <LogoSection />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Hidden mdUp>
                <Box sx={{ flexGrow: 1 }} />
            </Hidden>

            <Hidden mdDown>
                <Stack direction="row" gap={1.5}>
                    <Button
                        size={matchUpSm ? 'large' : 'medium'}
                        onClick={() => setOpenContact(true)}
                        startIcon={<ContactsOutlinedIcon fontSize="small" />}
                    >
                        {t('layout.Header.Contact.button')}
                    </Button>
                    <Button
                        size={matchUpSm ? 'large' : 'medium'}
                        startIcon={
                            matchUpSm && (
                                <Avatar
                                    src={undefined}
                                    sx={{
                                        ...theme.typography.mediumAvatar,
                                        cursor: 'pointer',
                                        bgcolor: 'primary.main',
                                    }}
                                    color="primary.main"
                                />
                            )
                        }
                        href="https://heureka-relations.com/"
                        target="_blank"
                    >
                        {t('layout.Header.actions.investors')}
                    </Button>
                    <Button
                        size={matchUpSm ? 'large' : 'medium'}
                        startIcon={
                            matchUpSm && (
                                <Avatar
                                    src={undefined}
                                    sx={{
                                        ...theme.typography.mediumAvatar,
                                        cursor: 'pointer',
                                        bgcolor: 'primary.main',
                                    }}
                                    color="primary.main"
                                />
                            )
                        }
                        href="https://los-main.com/login"
                        target="_blank"
                    >
                        {t('layout.Header.actions.login')}
                    </Button>
                </Stack>
            </Hidden>
            <Hidden mdUp>
                <ProfileSection onContactOpen={() => setOpenContact(true)} />
            </Hidden>
            <ContactDialog open={openContact} onClose={() => setOpenContact(false)} />
        </React.Fragment>
    );
};

export default Header;
