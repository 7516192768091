// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from 'components/LogoWarehouse';

// ==============================|| MAIN LOGO ||============================== //

// eslint-disable-next-line arrow-body-style
const LogoSection = () => {
    return (
        <ButtonBase disableRipple component="a" href=".">
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
