import { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// project imports
import { STORAGE_AUTH_USER, STORAGE_AUTH_TOKEN } from 'constants/store';
import * as authAPI from 'services/auth';
import useLocalStorage from 'hooks/useLocalStorage';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage(STORAGE_AUTH_TOKEN, null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const signin = ({ username, password }, callback) => {
        setLoading(true);
        setError(null);

        authAPI
            .login({ username, password })
            .then(({ accessToken, trackingId, codeExpiration }) => {
                if (accessToken) {
                    setToken(accessToken);
                }

                setError(null);
                callback({ trackingId, codeExpiration });
            })
            .catch(({ response }) => {
                const { error } = response.data;

                setError(error.message);
            })
            .finally(() => setLoading(false));
    };

    const signout = (callback) => {
        setToken(null);

        localStorage.removeItem(STORAGE_AUTH_USER);
        localStorage.removeItem(STORAGE_AUTH_TOKEN);
        localStorage.clear();

        callback();
    };

    const changeToken = (newToken) => {
        setToken(newToken);
    };

    const value = {
        token,
        error,
        signin,
        signout,
        loading,
        changeToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = { children: PropTypes.node };
